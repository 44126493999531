export const assets = {
  logo: 'assets/images/treasure_logo.svg',
  AuthAttachment1: 'assets/images/auth_attachment1.jpg',
  adminIcon: 'assets/images/profile.png',
  placeHolderLogo: 'assets/images/placeholder.png',
  DashboardIcon: 'assets/images/dashboard_icon.svg',
  viewIcon: 'assets/images/view_icon.svg',
  editIcon: 'assets/images/edit_icon.svg',
  deleteIcon: 'assets/images/delete_icon.svg',
  RevenueIcon: 'assets/images/revenue_icon.svg',
  CmsIcon: 'assets/images/cms_icon.svg',
  CategoryIcon: 'assets/images/category_icon.svg',
  NotificationIcon: 'assets/images/notification.svg',
  ReportIcon: 'assets/images/reports_icon.svg',
  searchIcon: 'assets/images/search_icon.svg',
  UsersIcon: 'assets/images/user_icon.svg',
  AnalyticIcon: 'assets/images/analytic_icon.svg',
  SubAdminIcon: 'assets/images/sub_admin_icon.svg',
  SubscriptionIcon: 'assets/images/subscription_icon.svg',
  ConsentRequestIcon: 'assets/images/consent_request_icon.svg',
  Signature: 'assets/images/signature.jpg',
  CameraIcon: 'assets/images/camera_icon.svg',
  RatingIcon: 'assets/images/rating_icon.svg',
  CarIcon: 'assets/images/car_icon.svg',
  CompanyIcon: 'assets/images/vendor_icon.svg',
  OrganisationIcon: 'assets/images/organisation_icon.svg',
  DriverIcon: 'assets/images/driver_icon.svg',
  ChatIcon: 'assets/images/chat_icon.svg',
  BookingIcon: 'assets/images/booking_icon.svg',
  ReportingIcon: 'assets/images/reporting_icon.svg',
  sendIcon: 'assets/images/send_icon.svg',
  logoMin:'assets/images/logo_min.png',
  offerIcon:'assets/images/offers_icon.svg',
  brandsIcon:'assets/images/brands_icon.svg',
  tiersIcon:'assets/images/tiers_icon.svg',
  noDataFound: 'assets/images/Nodat-found.gif'
};
